<template>
	<Layout>
		<Header />
		<section class="columns is-multiline analytics">
			<div v-for="i in over" :key="i.id"  class="column is-one-fifth">
				<div class="dashboard__box analytics__box p-3" :class="setExpression(i)">
					<div class="icon">
						<span v-html="require(`!html-loader!../assets/svg/${setExpression(i)}.svg`)"></span>
					</div>
					<div class="content">
						<h4 class="dashboard__box__value analytics__value">
							{{ i.total }}
						</h4>
						<span class="dashboard__box__type analytics__total">{{ setExpression(i) }}</span>
					</div>
				</div>
			</div>
		</section>

    <section class="columns is-multiline is-mobile column-video">
      <article class="column is-12-mobile">
        <div class="dashboard__panel is-relative">
          <video id="video-face" width="720" height="560" autoplay muted />
        </div>
      </article>
    </section>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
// import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import * as faceapi from 'face-api.js'
import moment from 'moment'
import server from '@/mixins/server'

export default {
	name: 'Analytics',
	mixins: [
    responsive,
    server
  ],
	components: {
		Layout,
		Header
	},
  data () {
    return {
      debug: false,
      stream: null,
      faces: [],
      lastTotalFaces: 0,
      currentTotalFaces: 0,
      screens: [],
      lastTime: new Date().getTime(),
      dbDetections: [],
      interval: null,
      over: [
        // { expression: 'passerby', total: 0 },
        { expression: 'total', total: 0 },
        { expression: 'neutral', total: 0 },
        { expression: 'happy', total: 0 },
        { expression: 'surprised', total: 0 },
        { expression: 'angry', total: 0 },
        { expression: 'sad', total: 0 },
        { expression: 'disgusted', total: 0 },
        { expression: 'fearful', total: 0 }
      ]
    }
  },
  mounted() {
    this.init()
  },
  destroyed() {
    this.closeWebCam()
    this.closeInterval()
  },
	methods: {
    init() {
      let savedDetections = localStorage.getItem('detections')

      if (savedDetections) {
        this.dbDetections = JSON.parse(savedDetections)
      }

      this.startWebCam()
    },
    startWebCam() {
      setTimeout(() => {
        this.startMovie()
        this.startInterval()
      }, 1000)
    },
    closeWebCam() {
      this.stream.getTracks().forEach((track) => {
        if (track.readyState == 'live' && track.kind === 'video') {
          track.stop()
        }
      })
    },
    startMovie() {
      const video = document.getElementById('video-face')

      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
        faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
        faceapi.nets.faceExpressionNet.loadFromUri('/models')
      ]).then(() => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: false })
          .then((stream) => {
            this.stream = stream
            video.srcObject = stream;
          })
          .catch(function(err) {
            console.log('Erro ao iniciar o vídeo, detalhes: ' + err)
          })
      })

      const getExpression = (expressions) => {
        let count = 0
        let expression = 'neutral'

        Object.entries(expressions).forEach(e => {
          if (e[1] > count) {
            count = e[1]
            expression = e[0]
          }
        })

        return expression
      }

      video.addEventListener('play', () => {
        const rect = video.getBoundingClientRect()
        const canvas = faceapi.createCanvasFromMedia(video)
        canvas.style.position = 'absolute'
        canvas.style.top = `${rect.top}px`
        canvas.style.left = `${rect.left}px`

        document.body.append(canvas)
        const displaySize = { width: parseInt(rect.width), height: parseInt(rect.height) }
        faceapi.matchDimensions(canvas, displaySize)

        setInterval(async () => {
          const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions().withFaceDescriptors()
          const resizedDetections = faceapi.resizeResults(detections, displaySize)

          canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)

          faceapi.draw.drawDetections(canvas, resizedDetections)
          faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
          faceapi.draw.drawFaceExpressions(canvas, resizedDetections)

          this.faces = []
          this.currentTotalFaces = 0
          if (detections.length > 0) {
            detections.forEach((d) => {
              /*
              angry
              disgusted
              fearful
              happy
              neutral
              sad
              surprised
              */

              this.faces.push(getExpression(d.expressions))
              ++this.currentTotalFaces
            })

            if (this.currentTotalFaces > 0 && this.debug) {
              console.log('Detectando: ' + this.currentTotalFaces + ' Face(s)')
            }
          }

          let currentTime = new Date().getTime()

          if (currentTime - this.lastTime >= 1500) {
            this.lastTime = currentTime

            if (this.currentTotalFaces > 0 && this.currentTotalFaces > this.lastTotalFaces) {
              if (this.debug) {
                console.log('Vai adicionar: ' + (this.currentTotalFaces - this.lastTotalFaces) + ' Face(s)')
                console.log(this.faces)
              }

              for (let i = this.lastTotalFaces; i < this.currentTotalFaces; i++) {
                if (this.debug) {
                  console.log('Vai Salvar: ' + this.faces[i] + ', às: ' + moment().format('YYYY-MM-DD HH:mm:ss'))
                }

                this.dbDetections.push({
                  data: moment().format('YYYY-MM-DD HH:mm:ss'),
                  place: '', // this.placeCode,
                  screen_id: '', // this.currentScreen,
                  expression: this.faces[i]
                })

                this.addViewAndExpression(this.faces[i])

                localStorage.setItem('detections', JSON.stringify(this.dbDetections))
              }
            }

            this.lastTotalFaces = this.currentTotalFaces;
          }
        }, 100)
      })
    },
    startInterval() {
      this.interval = setInterval(() => {
        if (this.dbDetections.length > 0 && !this.debug) {
          console.log('Send detections to server')
          /*
          Api.post(`${this.getUrlServer()}/faces-detections`, { detections: this.dbDetections })
              .then(() => {
                  this.dbDetections = []
                  localStorage.removeItem('detections')
              })
              .catch((err) => {
                  console.log(err)
              })
              */
        }
      }, 300000)
    },
    addViewAndExpression(expression) {
      let overIndex = this.over.findIndex(s => s.expression == expression)
      this.$set(this.over[overIndex], 'total', (this.over[overIndex]['total'] + 1))
      this.$set(this.over[0], 'total', (this.over[0]['total'] + 1))
    },
    closeInterval() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    },
    setExpression (over) {
      return over.expression == 'total' ? 'audience' : over.expression
    }
	}
}
</script>
